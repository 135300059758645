.table {
	word-break: normal;

	&.noHeader {
		& tbody {
			& tr:first-child {
				& td:first-child {
					border-top-left-radius: 6px;
				}

				& td:last-child {
					border-top-right-radius: 6px;
				}
			}
		}
	}
}

.tableHeader {
	--width: auto;

	width: var(--width);
	min-width: var(--width);

	@media (--medium-up) {
		--width: var(--col-width);
	}
}

.tableCell {
	& *:first-child {
		margin-top: 0;
	}

	& *:last-child {
		margin-bottom: 0;
	}
}
